export const DebugStatistic = {
    PACKETS_RECEIVED: {name: "Packets Received", fill: false, advancesItself: false},
    PACKETS_SENT: {name: "Packets Sent", fill: false, advancesItself: false},
    MIXER_CHANNELS: {name: "Mixer Channels", fill: false, advancesItself: false},
    VOICE_PEERS: {name: "Voice Peers", fill: false, advancesItself: false},
    MICROPHONE_LOUDNESS: {name: "Microphone Loudness", fill: true, advancesItself: true},
    TRACKED_PANNERS: {name: "Tracked Panners", fill: false, advancesItself: false},
    TIME_OFFSET_SECONDS: {name: "Time Offset Seconds", fill: false, advancesItself: false},
    VB_EVENTS: {name: "VB Events", fill: false, advancesItself: false},
    CACHED_STREAMS: {name: "Cached Streams", fill: false, advancesItself: false},
    PRELOADED_SOUNDS: {name: "Preloaded Sounds", fill: false, advancesItself: false},
}